import { ReactComponent as MapIcon } from '../../assets/swedensvg.svg';
import { ReactComponent as FontIcon } from '../../assets/Cloudarity_Icon_v2.svg';

interface Iinfo {    
    [key:string]: {
        [key:string]:string
    }
}
const WelcomePage = (info:Iinfo) => { 

    return (
        <main className='flex__center-c'>

            {/* Background Image */}
            <img className='header-img' src={`/${info?.WelcomePage?.Image_1}`} alt={`welcomepage1`} />

            {/* Company Logo , first section*/}
            <header className='section flex__center-c' style={{}}>              
                <FontIcon style={{width:"50vw",height:"50vh"}}/>              
            </header>



            {/* Map and info, second section */}
            <section className='flex__center-r flex__r-c' style={{height:"100vh", marginTop:"50vh"}}>

                {/* Swedish map, .svg */}
                <div style={{position:"relative", width:"40%", /* justifyContent:"space-between" */}} className='flex__center-r'>
                    <MapIcon className='mapicon' style={{width:"30vw",height:"50vh"}} strokeWidth="25vmax"/>                    
                </div>

                {/* Info and email */}
                <div className='flex__center-c' style={{width:"60%"}}>
                    <h2 style={{color:"white",fontSize:"1.2vmax",textAlign:"left"}}>
                        {info?.WelcomePage?.Info}
                    </h2>

                    <h2 style={{color:"white",fontSize:"1.2vmax"}}>
                        {info?.WelcomePage?.Email}
                    </h2>
                </div>

            </section>

            


        </main>
    )
}

export default WelcomePage