import { useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';

import { WelcomePage } from './container/index'

const App = () => {


    //* Get Info */
    interface ObjectStrings {[key:string]:string}
    interface ObjectAll {[key:string]: ObjectStrings}
    const [info, setInfo] = useState<ObjectAll>({})
    useEffect(() => {
        fetch('/info/info.json')
            .then(res => res.json())
            .then((res:ObjectAll) => {          
            setInfo(res)
            })            
        return () => {}
    }, [])



    return (
        <Router>
            <Routes>                            
                <Route path="/" index element={                            
                    <WelcomePage {...info} />                
                } />                                                   
            </Routes>  
        </Router>
    );
}

export default App;
